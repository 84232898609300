import WasmController from "react-lib/frameworks/WasmController";

import { TDocumentDefinitions } from "pdfmake/interfaces";
import moment from "moment";

// APIs

import ReportARReceiptShiftView from "issara-sdk/apis/ReportARReceiptShiftView_apps_INF";
import ReportCoverageView from "issara-sdk/apis/ReportCoverageView_apps_BIL";
import ReportDebtPayment from "issara-sdk/apis/ReportDebtPayment_apps_INF";
import ReportExpenseException from "issara-sdk/apis/ReportExpenseException_apps_BIL";
import ReportOutstandingDebtors from "issara-sdk/apis/ReportOutstandingDebtors_apps_INF";
import ReportRiskView from "issara-sdk/apis/ReportRiskView_apps_BIL";
import StationLogList from "issara-sdk/apis/StationLogList_apps_BIL";

// Form
import FormARremittancePayReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormARremittancePayReport";
import FormCoverageReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormCoverageReport";
import FormExpenseExceptionReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormExpenseExceptionReport";
import FormOutstandingDEBTOR from "react-lib/apps/HISV3/BIL/pdfFormBil/FormOutstandingDEBTOR";
import FormOverDueReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormOverDueReport";
import FormRepayDEBTReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormRepayDEBTReport";
import FormSeparateMedicalExpensesReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormSeparateMedicalExpensesReport";
import FormARremittanceReport from "react-lib/apps/HISV3/BIL/pdfFormBil/FormARremittanceReport";
import FormCostCenterMedFeeSlip from "react-lib/apps/HISV3/BIL/pdfFormBil/FormCostCenterMedFeeSlip";

// Common
import {
  SetErrorMessage,
  SetProperty,
  combinePdfFiles,
  mapOptions,
} from "../../common/CommonInterface";

// Interface
import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { printURL } from "react-lib/utils/printURL";

export type State = Partial<{
  // Sequence
  FinancialReportSequence: Partial<{
    sequenceIndex: "Start" | "Action" | null;
    bilStationLogOptions: OptionType[];
  }>;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "django" | "errorMessage" | "selectedDivision">
>;

export type PickedProps = Partial<Omit<PickedState, "">>;

type FilterType = Partial<
  {
    start_date: string;
    end_date: string;
    encounter_type: string;
    send_claim_status: string;
    patient_type: string;
  } & {
    [key in "coverage" | "division" | "payer" | "station" | "station_log" | "status"]: number;
  }
>;

type ReportUrlParams = Pick<
  FilterType,
  "coverage" | "encounter_type" | "end_date" | "payer" | "start_date" | "status"
>;

export type ReportTypes =
  | "COVERAGE"
  | "EXPENSE_EXCEPTION"
  | "OUTSTANDING_DEBTOR"
  | "OVERDUE"
  | "REMITTANCE"
  | "REPAY_DEBT"
  | "SEPARATE_COVERAGE";

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

export type OptionType = {
  key: number | string;
  value: number | string;
  text: string;
};

// Sequence
type SeqState = {
  sequence: "FinancialReport";
  restart?: boolean;
  clear?: boolean;
};

// Handle Action
type ActionType =
  // Action
  | {
      action: "PRINT_REPORT";
      type: ReportTypes;
      btnAction: string;
      data: FilterType;
      isPreview?: boolean;
    }
  | ({ action: "GET_BIL_STATION_LOG" } & Pick<FilterType, "end_date" | "start_date" | "station">);
// Method

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<State & PickedState>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // Sequence
  FinancialReportSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: Record<string, unknown> }
  | { message: "GetMasterData"; params: Record<string, unknown> };

export type Data = {
  division?: number;
  device?: number;
};

export const DataInitial = {};

// Const
const Masters = [
  ["coverage", {}],
  ["bilStation", {}],
  ["bilStationLog", {}],
  ["payer", {}],
  ["division", {}],
] as const;

export const OUTSTANDING_DEBTOR_OPTIONS = [
  { key: 1, text: "รอส่งเบิก", value: "NONE" },
  { key: 2, text: "ส่งเบิกแล้วรอผลตอบกลับ", value: "SENT_WAITING_RESULT" },
];

export const REPAY_DEBT_OPTIONS = [{ key: 1, text: "ส่งเบิกสำเร็จ", value: "SENT_SUCCESS" }];

export const ACTIONS = {
  PRINT_REPORT: "PRINT_REPORT",
  GET_BIL_STATION_LOG: "GET_BIL_STATION_LOG",
} as const;

type Handler<P = any, R = any> = (
  controller: WasmController<State & PickedState, Event, Data>,
  params: P
) => R;

/* ------------------- START ------------------- */
export const Start: Handler = async (controller, params) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  });

  const state = controller.getState();

  controller.setState({
    FinancialReportSequence: {
      ...state.FinancialReportSequence,
      sequenceIndex: "Action",
    },
  });
};

/* ------------------- ACTION ------------------- */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.PRINT_REPORT]: HandlePreviewReport,
    [ACTIONS.GET_BIL_STATION_LOG]: HandleGetBilStationLog,
  };

  const action = params.action;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

const HandleGetBilStationLog: Handler<Params<"GET_BIL_STATION_LOG">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [ACTIONS.GET_BIL_STATION_LOG]: "LOADING" },
  });

  const options = await GetBilStationLogOptions(controller, params);

  controller.setState({
    FinancialReportSequence: {
      ...state.FinancialReportSequence,
      bilStationLogOptions: options,
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [ACTIONS.GET_BIL_STATION_LOG]: null },
  });
};

const HandlePreviewReport: Handler<Params<"PRINT_REPORT">> = async (controller, params) => {
  const state = controller.getState();

  const TYPE_HANDLERS: Partial<{
    [K in ReportTypes]: {
      api: Handler<FilterType, Promise<[any[], any]>>;
      forms:
        | ((props: any) => Promise<TDocumentDefinitions>)
        | ((props: any) => Promise<TDocumentDefinitions>)[];
    };
  }> = {
    COVERAGE: {
      api: GetReportCoverage,
      forms: FormCoverageReport,
    },
    EXPENSE_EXCEPTION: {
      api: GetReportExpenseException,
      forms: FormExpenseExceptionReport,
    },
    REMITTANCE: {
      api: GetReportARReceiptShift,
      forms: [FormARremittanceReport, FormCostCenterMedFeeSlip],
    },
    SEPARATE_COVERAGE: {
      api: GetReportCoverageSeparate,
      forms: FormSeparateMedicalExpensesReport,
    },
    OUTSTANDING_DEBTOR: {
      api: GetReportOutstandingDebtors,
      forms: FormOutstandingDEBTOR,
    },
    OVERDUE: {
      api: GetReportRiskView,
      forms: FormOverDueReport,
    },
    REPAY_DEBT: {
      api: GetRepayDebtPayment,
      forms: FormRepayDEBTReport,
    },
  };

  const type = params.type;

  if (TYPE_HANDLERS[type]) {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "LOADING" },
    });

    const [result, error] = await TYPE_HANDLERS[type].api(controller, params.data);

    if (error) {
      SetErrorMessage(controller, {
        ...params,
        error,
        btnError: "",
        errorKey: params.btnAction,
      });
    } else {
      const docDefs: Promise<TDocumentDefinitions>[] = await [];
      const forms = TYPE_HANDLERS[type].forms;

      for (const [index, data] of result.entries()) {
        const formTypes = Array.isArray(forms) ? forms : [forms];

        if (type === "REMITTANCE") {
          if (data.report_name === "ใบนำส่งเงิน") {
            docDefs.push(formTypes?.[0]?.(data));
          } else {
            docDefs.push(formTypes?.[1]?.(data));
          }
        } else {
          const form = formTypes[index] || formTypes.slice(-1)[0];
          docDefs.push(form(data));
        }
      }

      const blobUrl = await combinePdfFiles(await Promise.all(docDefs));

      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.btnAction]: "SUCCESS" },
      });

      if (params.isPreview) {
        return globalThis.open(blobUrl);
      }

      printURL(blobUrl);
    }
  }
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */
const GetReportCoverage: Handler<FilterType, Promise<[any, any]>> = async (controller, params) => {
  const [result, error] = await ReportCoverageView.get({
    apiToken: controller.apiToken,
    params,
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = {
      items: {
        filterStartDate: params.start_date,
        filterEndDate: params.start_date,
      },
      reportCovData: result,
    };

    return [[data], null];
  }
};

const GetReportARReceiptShift: Handler<FilterType, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const state = controller.getState();

  const [result, error] = await ReportARReceiptShiftView.get({
    params,
    apiToken: controller.apiToken,
    extra: {
      device: controller.data.device,
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const toDate = moment();
    const year = Number.parseInt(toDate.format("YYYY")) + 543;

    const printDate = `${toDate.locale("th").format("D MMMM")} พ.ศ. ${year}`;

    const items: any[] = result?.items || [];

    const detail = items[0] || {};

    const closedUserName = detail.params?.closed_user_name || state.django?.user?.full_name;

    const remittance = {
      ...detail,
      params: {
        ...detail.params,
        closed_user_name: closedUserName,
      },
      date: printDate,
      user: state.django?.user?.full_name,
    };

    const remittancePays = items.slice(1).map((item) => ({
      ...item,
      params: {
        ...item.params,
        closed_user_name: closedUserName,
        start_date: item.params?.start_date || printDate, // issue 72918
        end_date: item.params?.end_date || printDate,
      },
      date: printDate,
      division: state.selectedDivision?.name,
    }));

    return [[remittance, ...remittancePays], null];
  }
};

const GetReportCoverageSeparate: Handler<FilterType, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const [result, error] = await ReportCoverageView.get({
    apiToken: controller.apiToken,
    params: { ...params, ...(!params.coverage && { divide_by_station: true }) },
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    let data: any = [
      {
        item: { filterStartDate: params.start_date, filterEndDate: params.start_date },
        reportCovData: result,
      },
    ];

    const fields: any[] = result.fields || [];

    if (fields.length > 0 && !params.coverage) {
      data = fields.map((field: any) => ({
        reportCovData: {
          params: {
            ...result.params,
            station: field.station,
            total_claimable: field.total_claimable,
            total_non_claimable: field.total_non_claimable,
            total_price: field.total, // issue 68268
            claimable_text: field.claimable_text,
          },
          fields: field.items,
          station: field.station,
          document_no: field.document_no,
        },
      }));
    }

    return [data, null];
  }
};

const GetReportExpenseException: Handler<FilterType, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const [result, error] = await ReportExpenseException.get({
    apiToken: controller.apiToken,
    params,
    extra: {
      division: controller.data.division,
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = {
      items: {
        filterStartDate: params.start_date,
        filterEndDate: params.start_date,
      },
      reportExpenseExceptData: result,
    };

    return [[data], null];
  }
};

// #const GetReportRemittance: Handler<FilterType, Promise<[any, any]>> = async (
//   controller,
//   params
// ) => {
//   return [[{}, {}], null];
// };

const GetReportOutstandingDebtors: Handler<ReportUrlParams, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const [result, error] = await ReportOutstandingDebtors.get({
    apiToken: controller.apiToken,
    params,
  });

  if (error) {
    return [null, error];
  } else {
    const data = {
      items: {
        filterStartDate: params.start_date,
        filterEndDate: params.end_date,
        filterSend_claim_status: params.status,
        filterCoverage: params.coverage,
        filterPayer: params.payer,
        filterPatient_type: params.encounter_type,
      },
      reportOutstandingDebtors: result,
    };

    return [[data], null];
  }
};

const GetReportRiskView: Handler<ReportUrlParams, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const [result, error] = await ReportRiskView.get({
    apiToken: controller.apiToken,
    params: { ...params, invoice_item_status: "PARTIAL_PAID", not_use_division_filter: true },
  });

  if (error) {
    return [null, error];
  } else {
    const data = {
      items: {
        filterStartDate: params.start_date,
        filterEndDate: params.start_date,
        filterSend_claim_status: params.status,
        filterCoverage: params.coverage,
        filterPayer: params.payer,
        filterPatient_type: params.encounter_type,
      },
      reportOverDue: result,
    };
    return [[data], null];
  }
};

const GetRepayDebtPayment: Handler<ReportUrlParams, Promise<[any, any]>> = async (
  controller,
  params
) => {
  const [result, error] = await ReportDebtPayment.get({
    apiToken: controller.apiToken,
    params: {
      ...params,
      invooice_item_status: "PARTIAL_PAID",
    },
  });

  if (error) {
    return [null, error];
  } else {
    const data = {
      items: {
        filterStartDate: params.start_date,
        filterEndDate: params.start_date,
        filterSend_claim_status: params.status,
        filterCoverage: params.coverage,
        filterPayer: params.payer,
        filterPatient_type: params.encounter_type,
      },
      reportRepayDEBT: result,
    };
    return [[data], null];
  }
};

const GetBilStationLogOptions: Handler<
  Pick<FilterType, "end_date" | "start_date" | "station">,
  Promise<OptionType[]>
> = async (controller, params) => {
  const [result] = await StationLogList.list({
    apiToken: controller.apiToken,
    params: {
      station: params.station || undefined,
      start_date: params.start_date || undefined,
      end_date: params.end_date || undefined,
    },
  });

  return mapOptions(result?.items || []);
};
